<template>
  <div class="page-container">
    <div class="header-body">
      <el-card class="card-box">
        <el-row :gutter="20">
          <el-col :span="18">
            <div class="search-form">
              <el-form ref="form" :model="queryInfo" label-width="80px">
                <el-form-item label="院校">
                  <el-select
                    v-model="queryInfo.schoolId"
                    placeholder="请选择院校"
                  >
                    <span v-for="(item, index) in schoolList" :key="index">
                      <el-option
                        :label="item.schoolName"
                        :value="item.id"
                      ></el-option>
                    </span>
                  </el-select>
                </el-form-item>
                <el-form-item label="专业">
                  <el-select
                    v-model="queryInfo.majorId"
                    placeholder="请选择专业"
                  >
                    <span v-for="(item, index) in majorList" :key="index">
                      <el-option
                        :label="item.majorName"
                        :value="item.id"
                      ></el-option>
                    </span>
                  </el-select>
                </el-form-item>
                <el-form-item label="时间">
                  <el-col :span="11">
                    <el-date-picker
                      type="date"
                      placeholder="开始日期"
                      value-format="yyyy-MM-dd"
                      v-model="queryInfo.startTime"
                      style="width: 100%"
                    ></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="2" style="text-align: center"
                    >-</el-col
                  >
                  <el-col :span="11">
                    <el-date-picker
                      type="date"
                      placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      v-model="queryInfo.endTime"
                      style="width: 100%"
                    ></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="" label-width="40px">
                  <el-select v-model="queryInfo.payStatus" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已支付" value="1"></el-option>
                    <el-option label="未支付" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="2" style="text-align: right">
            <el-dropdown @command="selectSearchType" split-button>
              {{ searchInfo.type }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="name">姓名</el-dropdown-item>
                <el-dropdown-item command="phone">手机号码</el-dropdown-item>
                <el-dropdown-item command="promoterName"
                  >推广人</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <el-input
                placeholder="请输入"
                class="input-with-select"
                clearable
                v-model.trim="searchInfo.value"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchHandle"
                ></el-button>
              </el-input>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <div class="search-form">
        <el-form ref="form" :model="exportExeclData" label-width="80px">
          <el-form-item label="院校">
            <el-select
              v-model="exportExeclData.schoolId"
              placeholder="请选择院校"
            >
              <span v-for="(item, index) in schoolList" :key="index">
                <el-option
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="专业">
            <el-select
              v-model="exportExeclData.majorId"
              placeholder="请选择专业"
            >
              <span v-for="(item, index) in majorList" :key="index">
                <el-option :label="item.majorName" :value="item.id"></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="exportExeclData.sex" placeholder="性别">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <el-col :span="11">
              <el-date-picker
                type="date"
                placeholder="开始日期"
                value-format="yyyy-MM-dd"
                v-model="exportExeclData.startTime"
                style="width: 100%"
              ></el-date-picker>
            </el-col>
            <el-col class="line" :span="2" style="text-align: center">-</el-col>
            <el-col :span="11">
              <el-date-picker
                type="date"
                placeholder="结束日期"
                value-format="yyyy-MM-dd"
                v-model="exportExeclData.endTime"
                style="width: 100%"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item>
            <div class="button-box">
              <el-button type="warning" @click="resetSearch">重置</el-button>
              <el-button
                type="info"
                @click="downloadSignTemp"
                v-permission="['student:import']"
                >下载导入模版</el-button
              >
              <el-button
                type="success"
                @click="importSignExcel"
                v-permission="['student:import']"
                >导入报名</el-button
              >
              <el-button
                type="success"
                @click="exportExecl"
                v-permission="['student:export']"
                >导出报名</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="studentList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50" />
          <el-table-column prop="name" label="姓名" />
          <el-table-column prop="applySchoolName" label="报名院校" />
          <el-table-column prop="applyMajorName" label="报名专业" />
          <el-table-column prop="phone" label="手机号码">
            <template v-slot="scoped">
              <span>{{ scoped.row.phone | hidePhone }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="promoterName" label="招生老师" />
          <el-table-column label="是否预报名">
            <template v-slot="scoped">
              <el-tag type="success" v-if="Number(scoped.row.preSignFlag) === 1"
                >是</el-tag
              >
              <el-tag type="danger" v-else>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="gmtCreate" label="报名时间" />
          <el-table-column label="支付状态">
            <template v-slot="scoped">
              <el-tag type="success" v-if="Number(scoped.row.payStatus) === 1"
                >已支付</el-tag
              >
              <el-tag type="danger" v-else>未支付</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="公司资源">
            <template v-slot="scoped">
              <el-tag
                type="success"
                v-if="Number(scoped.row.companyResource) === 1"
                >是</el-tag
              >
              <el-tag type="danger" v-else>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template v-slot="scoped">
              <el-button
                type="text"
                size="small"
                @click="openUpdateDialog(scoped.row.id)"
                >预览</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="printSignForm(scoped.$index)"
                >打印</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="downloadGradPhotoPath(scoped.row.gradPhotoPath)"
                >毕业证</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="downloadIdcard(scoped.row.idcardFrontPhotoPath)"
                >身份证正面</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="downloadIdcard(scoped.row.idcardBackPhotoPath)"
                >身份证反面</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="openUpdateMajorDialog(scoped.row.id)"
                v-permission="['student:save']"
                >更改专业</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="openChangePromoter(scoped.row.id)"
                v-permission="['student:updatePromoter']"
                >更改推广员</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="openChangeSignUpTime(scoped.row.id)"
                v-permission="['student:updateCreateTime']"
                >更改报名时间</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="deleteStudent(scoped.row.id)"
                v-permission="['student:delete']"
                >删除</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="openRemark(scoped.row.id)"
                >备注</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="pagination-body">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
    <sign-form-dialog :updateData="updateData" @closeDialog="closeDialog" />
    <major-dialog
      :updateMajorData="updateMajorData"
      @closeDialog="closeDialog"
    />
    <promoter-dialog :promoterData="promoterData" @closeDialog="closeDialog" />
    <sign-up-time-dialog
      :signUpTimeData="signUpTimeData"
      @closeDialog="closeDialog"
    />
    <remark :remarkData="remarkData" @closeDialog="closeDialog" />
    <print-temp id="sign-body" v-show="false" :formData="formData" />
  </div>
</template>

<script>
import signFormDialog from './dialog/signForm.vue'
import majorDialog from './dialog/updateMajor.vue'
import promoterDialog from './dialog/promoter.vue'
import signUpTimeDialog from './dialog/signUpTime.vue'
import permission from '@/directive/permission/index.js'
import remark from './dialog/remark.vue'

import {
  deleteStudent,
  filterStudentHttp,
  getSchoolList,
  getMajorBySchoolId
} from '@/api/api'
import printTemp from './components/printTemplate.vue'
import { exportService, importService } from '@/api/excel'
import store from '@/store/index'

export default {
  components: {
    signFormDialog,
    printTemp,
    majorDialog,
    promoterDialog,
    signUpTimeDialog,
    remark
  },
  data () {
    return {
      permissionList: [],
      studentList: [],
      schoolList: [],
      majorList: [],
      previewDialog: false,
      queryInfo: {
        currentPage: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        schoolId: '',
        majorId: '',
        payStatus: ''
      },
      total: 0,
      updateData: {
        isOpen: false,
        id: ''
      },
      updateMajorData: {
        isOpen: false,
        id: ''
      },
      promoterData: {
        isOpen: false,
        id: ''
      },
      signUpTimeData: {
        isOpen: false,
        id: ''
      },
      remarkData: {
        isOpen: false,
        id: ''
      },
      TEMPLATE_HTML_URL: `${process.env.BASE_URL}print/index.html`,
      formData: {},
      searchInfo: {
        type: '姓名',
        value: ''
      },
      searchForm: {
        region: '',
        sex: '',
        startTime: '',
        endTime: '',
        schoolId: '',
        majorId: ''
      },
      exportExeclData: {
        endTime: '',
        majorId: '',
        name: '',
        phone: '',
        promoterName: '',
        schoolId: '',
        sex: '',
        startTime: ''
      }
    }
  },
  directives: { permission },
  created () {
    this.filterStudent(this.queryInfo)
    this.getSchoolList()
    this.permissionList = store.getters['login/getPermission']
  },
  watch: {
    'exportExeclData.schoolId' (data) {
      this.getMajorBySchoolId(data)
      this.queryInfo.schoolId = data
      this.filterStudent(this.queryInfo)
    },
    'queryInfo.schoolId' (data) {
      this.getMajorBySchoolId(data)
    },
    'exportExeclData.majorId' (data) {
      this.queryInfo.majorId = data
      this.filterStudent(this.queryInfo)
    },
    'exportExeclData.sex' (data) {
      this.queryInfo.sex = data
      this.filterStudent(this.queryInfo)
    },
    'exportExeclData.startTime' (data) {
      this.queryInfo.startTime = data
      this.filterStudent(this.queryInfo)
    },
    'exportExeclData.endTime' (data) {
      this.queryInfo.endTime = data
      this.filterStudent(this.queryInfo)
    }
  },
  methods: {
    /* 导出excel */
    exportExecl () {
      var name = this.$moment().format('YYYY-MM-DD') + '导出全部'
      if (this.exportExeclData.startTime && this.exportExeclData.endTime) {
        name =
          this.exportExeclData.startTime + '至' + this.exportExeclData.endTime
      } else if (
        this.exportExeclData.startTime &&
        !this.exportExeclData.endTime
      ) {
        name =
          this.exportExeclData.startTime +
          '至' +
          this.$moment().format('YYYY-MM-DD')
      }
      exportService.exportSignExcel(this.exportExeclData, name).then(res => {
        console.log(res)
      })
    },
    // 下载导入模版
    downloadSignTemp () {
      exportService.downloadSignExcel().then(res => {
        console.log(res)
      })
    },
    // 导入报名信息
    importSignExcel () {
      // 创建一个隐藏的 input 元素
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.style.display = 'none'
      // 监听 input 元素的 change 事件
      fileInput.addEventListener('change', (event) => {
        const file = event.target.files[0]
        const fileData = new FormData()
        fileData.append('file', file)
        if (file) {
          console.log(file)
          this.$confirm('此操作将导入报名信息, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            try {
              const res = await importService.importStuSign(fileData)
              console.log(res)
              this.$message({
                type: 'success',
                message: '导入成功'
              })
              this.filterStudent(this.queryInfo)
            } catch (error) {
              console.log('err', error)
              this.$message({
                type: 'error',
                message: error.message || '导入失败'
              })
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        }
      })
      // 将 input 元素添加到 body 并触发点击
      document.body.appendChild(fileInput)
      fileInput.click()
      // 选择文件后，移除 input 元素
      fileInput.remove()
    },
    /* 选择搜索类型 */
    selectSearchType (type) {
      switch (type) {
        case 'phone':
          this.searchInfo.type = '手机号码'
          break
        case 'name':
          this.searchInfo.type = '姓名'
          break
        case 'promoterName':
          this.searchInfo.type = '推广人'
          break
        default:
          this.searchInfo.type = '姓名'
      }
    },
    /* 搜索 */
    searchHandle () {
      switch (this.searchInfo.type) {
        case '姓名':
          this.queryInfo.name = this.searchInfo.value
          break
        case '手机号码':
          this.queryInfo.name = this.searchInfo.value
          break
        case '推广人':
          this.queryInfo.name = this.searchInfo.value
          break
      }
      this.queryInfo.currentPage = 1
      this.filterStudent(this.queryInfo)
    },
    /* 重置搜索 */
    resetSearch () {
      this.searchInfo.value = ''
      this.queryInfo = {
        currentPage: 1,
        pageSize: 10,
        payStatus: ''
      }
      this.filterStudent(this.queryInfo)
      this.exportExeclData = {}
    },
    /* 过滤搜索报名信息 */
    filterStudent (data) {
      filterStudentHttp(data).then(res => {
        this.studentList = res.data.list
        this.total = res.data.total
      })
    },
    /* 获取学校列表 */
    getSchoolList () {
      getSchoolList({
        currentPage: 1,
        pageSize: 20
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    /* 根据学校id获取专业列表 */
    getMajorBySchoolId (schoolId) {
      getMajorBySchoolId(schoolId).then(res => {
        this.majorList = res.data
      })
    },
    /* 删除推广员 */
    deleteStudent (id) {
      this.$confirm('此操作将永久删除该报名, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteStudent(id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.filterStudent(this.queryInfo)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /* 分页选择 */
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.filterStudent(this.queryInfo)
    },
    handleCurrentChange (size) {
      this.queryInfo.currentPage = size
      this.filterStudent(this.queryInfo)
    },
    /* 打开更新弹窗 */
    openUpdateDialog (id) {
      this.updateData = {
        isOpen: true,
        id: id
      }
    },
    /* 打开更新专业弹窗 */
    openUpdateMajorDialog (id) {
      this.updateMajorData = {
        isOpen: true,
        id: id
      }
    },
    /* 打开更改推广员弹窗 */
    openChangePromoter (id) {
      this.promoterData = {
        isOpen: true,
        id: id
      }
    },
    /* 打开更改报名时间弹窗 */
    openChangeSignUpTime (id) {
      this.signUpTimeData = {
        isOpen: true,
        id: id
      }
    },
    /* 打开备注弹窗 */
    openRemark (id) {
      this.remarkData = {
        isOpen: true,
        id: id
      }
    },
    // 关闭对话框
    closeDialog (data) {
      this.remarkData.isOpen = data.isOpen
      this.updateData.isOpen = data.isOpen
      this.updateMajorData.isOpen = data.isOpen
      this.promoterData.isOpen = data.isOpen
      this.signUpTimeData.isOpen = data.isOpen
      if (data.type === 'success') {
        this.filterStudent(this.queryInfo)
      }
    },
    /* 下载毕业证 */
    downloadGradPhotoPath (url) {
      if (url) {
        window.open(url, '_blank')
      } else {
        this.$message.error('当前用户未上传图片')
      }
    },
    /* 下载身份证 */
    downloadIdcard (url) {
      if (url) {
        window.open(url, '_blank')
      } else {
        this.$message.error('当前用户未上传图片')
      }
    },
    /* 打印报名表单 */
    printSignForm (index) {
      this.formData = this.studentList[index]
      const report = document.querySelector('#sign-body')
      const iframe = document.createElement('iframe')
      const iframeStyle =
        'border:0;position:absolute;width:0px;height:0px;right:0px;top:0px;'
      iframe.style.cssText = iframeStyle
      const id = new Date().getTime()
      iframe.id = id
      iframe.src = this.TEMPLATE_HTML_URL
      document.body.appendChild(iframe)
      iframe.onload = () => {
        iframe.contentDocument.querySelector(
          '#signForm'
        ).innerHTML = this.nodeToString(report)
        setTimeout(() => {
          iframe.contentWindow.print()
        }, 2000)
        setTimeout(() => {
          iframe.remove()
        }, 2000)
      }
    },
    nodeToString (node) {
      let tmpNode = document.createElement('div')
      tmpNode.appendChild(node.cloneNode(true))
      const str = tmpNode.innerHTML
      tmpNode = node = null
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
.header-body {
  .search-form {
    padding: 20px 10px 0 0;
    background-color: #fff;
    .el-form {
      display: flex;
      // justify-content: flex-end;
      .button-box {
        display: flex;
      }
    }
  }
}
.page-container {
  .el-card {
    ::v-deep .el-card__body {
      // padding: 20px 20px 0 0;
      .el-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  .card-box {
    ::v-deep .el-card__body {
      padding: 20px 20px 0 0;
    }
  }
  .pagination-body {
    .el-pagination {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
