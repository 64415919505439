<template>
  <div class="page-container">
    <div class="page-body">
      <div class="header">
        <div class="title">南大教育</div>
        <div class="subtitle">成人教育报名表</div>
      </div>
      <div class="content">
        <div class="date-body">
          <ul>
            <li>填报日期：</li>
            <li>{{ formData.gmtCreate | momentFormater("YYYY") }}</li>
            <li>年</li>
            <li>{{ formData.gmtCreate | momentFormater("MM") }}</li>
            <li>月</li>
            <li>{{ formData.gmtCreate | momentFormater("DD") }}</li>
            <li>日</li>
          </ul>
        </div>
        <div class="table-body">
          <table class="table" width="100%" border="1">
            <tr>
              <td colspan="9">
                <h2>基本信息</h2>
              </td>
              <td rowspan="4" width="15%">
                <img class="avatar" :src="formData.certificatePhotoPath" />
              </td>
            </tr>
            <tr>
              <td width="17%" colspan="2">姓名</td>
              <td width="14%" colspan="2">
                <el-input v-model="formData.name"></el-input>
              </td>
              <td width="14%">性别</td>
              <td width="12%">
                <el-select v-model="formData.sex" placeholder="请选择">
                  <el-option label="男" :value="Number(1)"> </el-option>
                  <el-option label="女" :value="Number(2)"> </el-option>
                </el-select>
              </td>
              <td width="14%" colspan="2">民族</td>
              <td width="12%">
                <el-input v-model="formData.nation"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="2">出生日期</td>
              <td colspan="2">
                <el-input v-model="formData.birthday"></el-input>
              </td>
              <td>政治面貌</td>
              <td colspan="4">
                <el-input v-model="formData.politicalOutlook"></el-input>
              </td>
            </tr>
            <tr>
              <td>身份证号</td>
              <td colspan="8">
                <el-input v-model="formData.idCard"></el-input>
              </td>
            </tr>
            <tr>
              <td>现住址</td>
              <td colspan="9">
                <el-input v-model="formData.address"></el-input>
              </td>
            </tr>
            <tr>
              <td>户籍所在地</td>
              <td colspan="4">
                <el-input v-model="formData.registerResidence"></el-input>
              </td>
              <td>考试地点</td>
              <td colspan="2">
                <el-input v-model="formData.examPlace"></el-input>
              </td>
              <td>是否退伍军人</td>
              <td>
                <el-select v-model="formData.veterans" placeholder="请选择">
                  <el-option label="是" :value="Number(1)"> </el-option>
                  <el-option label="否" :value="Number(2)"> </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td>考生类别</td>
              <td colspan="4">
                <el-input v-model="formData.examType"></el-input>
              </td>
              <td>考前学历</td>
              <td colspan="4">
                <el-input v-model="formData.preExamEdu"></el-input>
              </td>
            </tr>
            <tr>
              <td>毕业学校</td>
              <td colspan="4">
                <el-input v-model="formData.gradSchool"></el-input>
              </td>
              <td>毕业专业</td>
              <td colspan="4">
                <el-input v-model="formData.gradMajor"></el-input>
              </td>
            </tr>
            <tr>
              <td>毕业时间</td>
              <td colspan="4">
                <el-input v-model="formData.gradTime"></el-input>
              </td>
              <td>毕业证书号</td>
              <td colspan="4">
                <el-input v-model="formData.gradCardNum"></el-input>
              </td>
            </tr>
            <tr>
              <td>工作单位</td>
              <td colspan="10">
                <el-input v-model="formData.workUnit"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <h2>联系信息</h2>
              </td>
            </tr>
            <tr>
              <td>手机号码</td>
              <td colspan="4">
                <el-input v-model="formData.phone"></el-input>
              </td>
              <td>电子邮箱</td>
              <td colspan="4">
                <el-input v-model="formData.email"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <h2>紧急联系人</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">姓名</td>
              <td colspan="2">关系</td>
              <td colspan="4">工作单位、职务</td>
              <td colspan="2">联系电话</td>
            </tr>
            <tr>
              <td colspan="2">
                <el-input v-model="formData.emerContactPerson"></el-input>
              </td>
              <td colspan="2">
                <el-input v-model="formData.emerContactRealtion"></el-input>
              </td>
              <td colspan="4">
                <el-input v-model="formData.emerContactUnit"></el-input>
              </td>
              <td colspan="2">
                <el-input v-model="formData.emerContactPhone"></el-input>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateStudent } from '@/api/api'

export default {
  props: {
    formData: {
      type: Object
    }
  },
  formData () {
    return {}
  },
  methods: {
    /* 更新学生信息 */
    updateStudent () {
      updateStudent(this.formData).then((res) => {
        this.$emit('updateSuccess')
        this.$message({
          message: '更新成功',
          type: 'success'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .page-body {
    .flexBox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header {
      @extend .flexBox;
      flex-direction: column;
      padding-bottom: 40px;
      .title,
      .subtitle {
        font-size: 30px;
        padding: 5px 0;
        font-weight: bold;
      }
      .title {
        letter-spacing: 10px;
      }
    }
    .content {
      .date-body {
        padding: 10px 0;
        ul {
          display: flex;
        }
      }
      .table-body {
        .table {
          width: 100%;
          border-collapse: collapse;
          tr {
            td {
              text-align: center;
              padding: 0 10px;
              font-size: 14px;
              line-height: 40px;
              height: 40px;
              h2 {
                text-align: left;
              }
              .avatar {
                width: 100%;
                max-height: 300px;
                min-height: 150px;
                vertical-align: middle;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
</style>
