<template>
  <div class="page-container">
    <div class="page-body">
      <div class="header">
        <div class="title">南大教育</div>
        <div class="subtitle">成人教育报名表</div>
      </div>
      <div class="content">
        <div class="date-body">
          <ul>
            <li>填报日期：</li>
            <li>{{ formData.gmtCreate | momentFormater("YYYY") }}</li>
            <li>年</li>
            <li>{{ formData.gmtCreate | momentFormater("MM") }}</li>
            <li>月</li>
            <li>{{ formData.gmtCreate | momentFormater("DD") }}</li>
            <li>日</li>
          </ul>
        </div>
        <div class="table-body">
          <table class="table" width="100%" border="1">
            <tr>
              <td colspan="9">
                <h2>基本信息</h2>
              </td>
              <td rowspan="4" width="15%">
                <img class="avatar" :src="formData.certificatePhotoPath" />
              </td>
            </tr>
            <tr>
              <td width="17%" colspan="2">姓名</td>
              <td width="14%" colspan="2">
                {{ formData.name }}
              </td>
              <td width="14%">性别</td>
              <td width="12%" v-if="formData.sex == 1">男</td>
              <td width="12%" v-if="formData.sex == 2">女</td>
              <td width="14%" colspan="2">民族</td>
              <td width="12%">
                {{ formData.nation }}
              </td>
            </tr>
            <tr>
              <td colspan="2">出生日期</td>
              <td colspan="2">
                {{ formData.birthday }}
              </td>
              <td>政治面貌</td>
              <td colspan="4">
                {{ formData.politicalOutlook }}
              </td>
            </tr>
            <tr>
              <td>身份证号</td>
              <td colspan="8">
                {{ formData.idCard }}
              </td>
            </tr>
            <tr>
              <td>现住址</td>
              <td colspan="9">
                {{ formData.address }}
              </td>
            </tr>
            <tr>
              <td>户籍所在地</td>
              <td colspan="4">
                {{ formData.registerResidence }}
              </td>
              <td>考试地点</td>
              <td colspan="2">
                {{ formData.examPlace }}
              </td>
              <td>是否退伍军人</td>
              <td width="12%" v-if="formData.veterans == 1">是</td>
              <td width="12%" v-if="formData.veterans == 2">否</td>
            </tr>
            <tr>
              <td>考生类别</td>
              <td colspan="4">
                {{ formData.examType }}
              </td>
              <td>考前学历</td>
              <td colspan="4">
                {{ formData.preExamEdu }}
              </td>
            </tr>
            <tr>
              <td>毕业学校</td>
              <td colspan="4">
                {{ formData.gradSchool }}
              </td>
              <td>毕业专业</td>
              <td colspan="4">
                {{ formData.gradMajor }}
              </td>
            </tr>
            <tr>
              <td>毕业时间</td>
              <td colspan="4">
                {{ formData.gradTime }}
              </td>
              <td>毕业证书号</td>
              <td colspan="4">
                {{ formData.gradCardNum }}
              </td>
            </tr>
            <tr>
              <td>工作单位</td>
              <td colspan="10">
                {{ formData.workUnit }}
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <h2>联系信息</h2>
              </td>
            </tr>
            <tr>
              <td>手机号码</td>
              <td colspan="4">
                {{ formData.phone }}
              </td>
              <td>电子邮箱</td>
              <td colspan="4">
                {{ formData.email }}
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <h2>紧急联系人</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">姓名</td>
              <td colspan="2">关系</td>
              <td colspan="4">工作单位、职务</td>
              <td colspan="2">联系电话</td>
            </tr>
            <tr>
              <td colspan="2">
                {{ formData.emerContactPerson }}
              </td>
              <td colspan="2">
                {{ formData.emerContactRealtion }}
              </td>
              <td colspan="4">
                {{ formData.emerContactUnit }}
              </td>
              <td colspan="2">
                {{ formData.emerContactPhone }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object
    }
  },
  data () {
    return {}
  }
}
</script>

<style></style>
