import axios from 'axios'
import { Loading } from 'element-ui'

/* 请求导出excel */
const exportExcel = (host, path, method, params, filename) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${host}${path}`,
      method: method,
      responseType: 'blob',
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: window.sessionStorage.getItem('token')
      }
    }).then((res) => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      resolve(res)
    }).catch(error => {
      reject(error.response.data)
    })
  })
}

/* 请求导入excel */
const importExcel = (host, path, method, params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${host}${path}`,
      method: method,
      data: params,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: window.sessionStorage.getItem('token')
      }
    }).then((res) => {
      console.log(res)
      resolve(res)
    }).catch((error) => {
      reject(error.response.data)
    })
  })
}

/* 请求导出word */
export function exportWord (host, path, method, params, filename) {
  const loading = Loading.service({ text: '导出文档玩命生成中，请稍后。。。' })
  return new Promise((resolve, reject) => {
    axios({
      url: `${host}${path}`,
      method: method,
      responseType: 'blob',
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: window.sessionStorage.getItem('token')
      }
    }).then((res) => {
      const blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `${filename}.docx`
      document.body.appendChild(a)
      a.click()
      resolve(res)
      loading.close()
    }).catch(error => {
      reject(error.response.data)
      loading.close()
    })
  })
}

export {
  exportExcel,
  importExcel
}
