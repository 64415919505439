
import { exportExcel, importExcel, exportWord } from '@/utils/service/excelHttp.js'
import { host } from '@/utils/service/url.js'

import moment from 'moment/moment'

/* 导出excel */
const exportService = {
  /* 下载导入课程模板 */
  exportSignExcel: (params, name) => {
    return exportExcel(host, '/student/exportExecl', 'post', params, name + '报名数据')
  },
  /* 下载导入报名信息模板 */
  downloadSignExcel: (params, name) => {
    return exportExcel(host, 'student/downloadTemplate', 'get', params, '报名信息导入模版')
  }
}

/* 导入excel */
const importService = {
  /* 导入机构 */
  importSch: (params) => {
    return importExcel(host, 'sch/info/importSchInfo', 'post', params)
  },
  /* 导入报名信息 */
  importStuSign: (params) => {
    return importExcel(host, 'student/import', 'post', params)
  }
}

// 导出word
const exportWordService = {
  // 导出指定学生信息（word）
  exportOneStuInfo: (id, name) => {
    return exportWord(host, '/student/exportOneStuInfo/' + id, 'post', id, name + '报名表' + moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
  }
}

export { exportService, importService, exportWordService }
